footer {
    padding: 44px $space-horizontal;
    background: $teal;
    display: flex;
    color: $white;
    font-weight: 300;
    justify-content: flex-start;
    @include laptop {
        flex-wrap: wrap;
    }
    @include tablet {
        padding: 34px $space-horizontal-smaller;
    }
    @include mobileMedium {
        padding: 28px $space-horizontal-smallest;
    }
}

footer .social {
    a {
        width: 28px;
        height: 28px;
        display: inline-block;
        margin-right: 16px;
        margin-bottom: 16px;
        svg {
            width: 100%;
            height: 100%;
            fill: white;
        }
        &:hover {
            opacity: 0.8;
            text-decoration: underline;
        }
    }
}

footer .info {
    padding-right: 5rem;
    @include laptop {
        width: 100%;
        order: 1;
        margin-top: 50px;
        padding-right: 0;
    }
    p {
        font-family: serif;
        margin-bottom: 28px;
        font-size: 1.8rem;
        line-height: 1.6;
        font-weight: 300;
        br {
            @include laptop {
                display: none;
            }
            @include mobileLarge {
                display: block;
            }
            @include mobileSmall {
                display: none;
            }
        }
    }
}

footer .company,
footer .services {
    font-size: 1.8rem;
    font-weight: 300;
    padding-right: 5rem;

    .title {
        margin-bottom: 28px;
        font-weight: 500;
        a {
            text-decoration: none;
            color: inherit;
            display: block;
        }
        @include mobileMedium {
            font-size: 3rem
        }
    }
    .links {
        display: flex;
        flex-direction: column;
        a {
            color: white;
            text-decoration: none;
            margin-bottom: 14px;
            line-height: 1.2;
            &:hover {
                opacity: 0.8;
                text-decoration: underline;
            }
            @include mobileMedium {
                font-size: 3.2rem;
                margin-bottom: 22px;

            }
        }
    }
}

footer .company {
    @include mobileLarge {
        margin-bottom: 32px;
    }
}
