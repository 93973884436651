.twocolumn-wrapper main .section-wrapper.section-wrapper--quiz-result {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobileLarge {
        flex-direction: column;
    }
    .section-image {
        min-width: 25%;
        max-width: 25%;
        width: 25%;
        background-size: contain;
        background-color: transparent;
        &.icon-success {
            background-image: url(/assets/svg/tick.svg);
        }
        &.icon-failure {
            background-image: url(/assets/svg/cross.svg);
        }
        @include tablet {
            height: 160px;
            // min-width: 140px;
        }
        @include mobileLarge {
            margin-bottom: 32px;
            margin-top: 10px;
            height: 110px;
            min-width: 80px;
            width: 100%;
            max-width: 100%;
        }
        @include mobileMedium {
            height: 80px;
            min-width: 80px;
            // width: 40px;
            margin-top: 20px;
        }
    }
    .section-info {
        min-width: 70%;
        max-width: 70%;
        width: 70%;
        margin-bottom: 0;
        &.section-info--neutral {
            min-width: 100%;
            max-width: 100%;
            width: 100%;
        }
        @include mobileLarge {
            min-width: 95%;
            max-width: 95%;
            width: 95%;
            text-align: center;
        }
        .section__title {
            line-height: 1.4;
        }
        .section__desc--content {
            font-size: 2.4rem;
        }
        .contact__mode.contact__mode--phone {
            margin: 3.4rem 0px;
            text-transform: uppercase;
            .contact__label {
                margin-bottom: 14px;
            }
        }
    }
    .section-info--full {
        width: 100%;
        margin-top: 4rem;
        .subsection {
            margin-top: 4rem;
        }
        p {
            font-size: 1.7rem;
            line-height: 1.5;
            margin-bottom: 1rem;
        }
        h2 {
            font-size: 2.4rem;
            font-weight: 600;
            line-height: 1.3;
        }
        h3 {
            font-weight: 600;
            line-height: 1.5;
        }
        ul li {
            list-style-type: disc;
            margin-left: 2.4rem;
            font-size: 2rem;
            line-height: 1.4;
            margin-top: 2rem;
            font-weight: normal;
            opacity: 0.95;
        }
        ul.content-with-images li {
            list-style-type: none;
            margin-left: 0;
            display: flex;
            @include mobileMedium {
                flex-direction: column-reverse;
            }
            p {
                font-size: 2rem;
            }
            .content-right {
                width: 30%;
                margin: 0 2rem;
                min-width: 30%;
                max-width: 30%;
                @include mobileMedium {
                    margin: 2rem 0rem;
                    min-width: 80px;
                    max-width: 150px;
                    width: 100px;
                    min-height: 60px;
                    max-height: 100px;
                    height: 80px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    max-height: 100px;
                    object-fit: contain;
                }
            }
        }
    }
}