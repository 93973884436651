.section-wrapper.section-wrapper--quiz {
    padding-bottom: 0;
    .section-info .section__title {
        line-height: 1.3;
    }
    .quiz__cards {
        margin: 5%;
        min-height: 50vh;
        @include mobileLarge {
            padding: 0px $space-horizontal-smaller 32px;
            margin: 0;
        }
        @include mobileMedium {
            padding: 0px $space-horizontal-smallest 18px;
        }
        .quiz__card {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            transform: translateY(1.5rem);
            opacity: 0.3;
            height: 0;
            overflow: hidden;
            &.visible {
                transform: translateY(0);
                opacity: 1;
                height: auto;
                transition: 0.55s cubic-bezier(0.26, 0.54, 0.66, 0.9);
            }
            @include mobileLarge {
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
            .quiz__image {
                width: 400px;
                height: 200px;
                margin-top: 0.5rem;
                margin-right: 32px;
                @include laptop {
                    width: 340px;
                    height: 180px;
                }
                @include tablet {
                    width: 280px;
                    height: 150px;
                }
                @include mobileLarge {
                    margin-top: 0;
                    margin-right: 0;
                    margin-bottom: 32px;
                }
                @include mobileSmall {
                    width: 100%;
                    height: 160px;
                }
                .bg {
                    height: 100%;
                    background-color: transparent;
                    background-size: contain;
                }
            }
            .quiz__content {
                flex-grow: 1;
                max-width: 64rem;
                @include mobileLarge {
                    max-width: 100%;
                    width: 100%;
                }
                .quiz__question {
                    text-align: left;
                    font-size: 3.4rem;
                    line-height: 1.4;
                    margin-bottom: 2rem;
                    font-weight: 600;
                }
                .quiz__options {
                    display: flex;
                    margin: 3.8rem 0px 3.2rem;
                    .quiz__option {
                        flex-grow: 1;
                        color: $white;
                        font-size: 2.6rem;
                        padding: 2rem 0;
                        font-weight: 500;
                        cursor: pointer;
                        outline: 0;
                        border: 0;
                        -webkit-tap-highlight-color: transparent !important;
                        &:not(:last-of-type) {
                            margin-right: 20px;
                        }
                        &.is-selected {
                            border: 3px solid rgba(0, 0, 0, 0.2);
                            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
                        }
                        &.is-not-selected {
                            background-color: #d1d1d1 !important;
                        }
                    }
                }
                .quiz__controls {
                    display: flex;
                    justify-content: space-between;
                    -webkit-tap-highlight-color: transparent !important;
                    @include mobileMedium {
                        flex-wrap: wrap;
                    }
                    @include mobileSmall {
                        flex-direction: column;
                    }
                    button {
                        background: none;
                        border: 0;
                        outline: 0;
                        padding: 20px;
                        cursor: pointer;
                        font-size: 2rem;
                        font-weight: 500;
                        text-transform: uppercase;
                        color: $greyDark;
                        pointer-events: all;
                        transition: 0.3s;
                        &:hover {
                            color: $black;
                        }
                        &:first-of-type {
                            margin-left: -20px;
                            @include mobileSmall {
                                margin-left: 0px;
                            }
                        }
                        &:last-of-type {
                            margin-right: -20px;
                            @include mobileSmall {
                                margin-right: 0px;
                            }
                        }
                        &.is-disabled {
                            cursor: not-allowed;
                            opacity: 0.25;
                            pointer-events: none;
                            transition: 0.3s;
                        }
                        &.restart {
                            @include mobileSmall {
                                order: 3;
                            }
                        }
                    }
                }
            }
        }
    }
}
