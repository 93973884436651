.onecolumn-wrapper {
    .section-wrapper {
        padding: 32px $space-horizontal 64px;
        @include tablet {
            padding: 32px $space-horizontal-smaller 64px;
        }
        @include mobileMedium {
            padding: 24px $space-horizontal-smallest 32px;
        }
        .section-info {
            max-width: 100rem;
            margin-bottom: 4rem;
        }
        .section__title {
            font-size: 3.7rem;
            font-weight: 700;
            margin-bottom: 1.5rem;
        }
        .section__heading {
            font-size: 2.9rem;
            font-weight: 600;
            line-height: 1.3;
            margin: 20px auto;
        }
        .section__desc {
            font-size: 1.8rem;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.6);
        }
        .section__desc--content {
            font-size: 2.6rem;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.6);
            font-family: $fontSerif;
        }
        section.full-width {
            width: 100vw;
            left: calc(50% - 50vw);
            position: relative;
        }
    }
}
