@charset "utf-8";

@import 'vendor/reset';

@import 'base/colors';
@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/root';
@import 'base/helpers';
@import 'base/components';
@import 'base/images';

@import 'pages/header';
@import 'pages/footer';
@import 'pages/homepage';
@import 'pages/onecolumn';
@import 'pages/twocolumn';
@import 'pages/aside';
@import 'pages/services';
@import 'pages/servicedetail';
@import 'pages/press';
@import 'pages/blog';
@import 'pages/blogdetail';
@import 'pages/about';
@import 'pages/quiz';
@import 'pages/quizresult';
@import 'pages/events';
@import 'pages/thankyou';
@import 'pages/storydetail';
@import 'pages/faq';
@import 'pages/form';
@import 'pages/_healthprofessional';
