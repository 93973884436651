body.body-thankyou {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .onecolumn-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
    }
}

.section-wrapper.section-wrapper--thankyou {
    display: flex;
    flex-direction: row;
    @include mobileLarge {
        flex-direction: column;
    }
    .section-image {
        height: 150px;
        min-width: 120px;
        width: 180px;
        margin-right: 32px;
        background-image: url(/assets/svg/tick.svg);
        background-size: contain;
        background-color: transparent;
        @include tablet {
            height: 160px;
            min-width: 180px;
            width: 180px;
        }
        @include mobileLarge {
            margin-right: 0;
            margin-bottom: 32px;
            margin-top: 10px;
            height: 110px;
            min-width: 80px;
            width: 110px;
        }
        @include mobileMedium {
            height: 80px;
            min-width: 80px;
            width: 40px;
            margin-top: 20px;
        }
    }
    .section-info {
        margin-bottom: 0px;
        .section__title {
            line-height: 1.4;
        }
        .contact__mode.contact__mode--phone {
            margin: 3.4rem 0px;
            text-transform: uppercase;
            .contact__label {
                margin-bottom: 14px;
            }
        }
    }
}
