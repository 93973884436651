body.post-template {
    font-family: $fontSanSerif;
    main.content {
        font-size: 160%;
        .post {
            margin-top: 4rem;
        }
    }
    aside.read-next {
        font-size: 160%;
        margin-bottom: 0;
    }
    
    article.post {
        line-height: 1.4;
        header.post-header .post-title {
            font-size: 4rem;
            font-weight: 600;
            margin-bottom: 1rem;
            line-height: 1.4;
        }
        section.post-content {
            h1 { font-size: 4rem; margin: 2.8rem auto 1.6rem; }
            h2 { font-size: 3.2rem; margin: 2.8rem auto 1.4rem; }
            h3 { font-size: 2.9rem; margin: 2.1rem auto 1.1rem; }
            h4 { font-size: 2.6rem; margin: 1.8rem auto 0.9rem; }
            h5 { font-size: 2rem; margin: 1.5rem auto 0.7rem; }
            h6 { font-size: 1.6rem; margin: 1.4rem auto 0.6rem; }
            ul, ol {
                list-style-type: initial;
                margin-left: 2.4rem;
            }
            p,
            ul li,
            ol li {
                font-size: 1.8rem;
                line-height: 1.5;
                font-family: $fontSerif;
            }
            p {
                margin-bottom: 1rem;
            }
            pre {
                font-size: 1.7rem;
                line-height: 1.4;
            }
            code {
                font-family: monospace;
            }
            a {
                color: $teal;
                &:hover {
                    color: $tealDarker;
                }
            }
        }
    }
    aside.read-next {
        section.post {
            font-size: 3.2rem;
            &:before {
                font-weight: 600;
            }
            p {
                font-size: 2.2rem;
                line-height: 1.3;
            }
            h2 {
                margin: 2rem auto 1.4rem;
            }
        }
    }
}
